footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: #1c0761; */
  color: white;
  padding: 1rem;
  bottom: 0;
}

footer a {
  color: white;
  text-decoration: none;
}

/* @media screen and (max-width: 321px) {
 footer p {
    font-size: 15px;
  }

  .footer-container{
    margin-left: 0.6rem;
  }
} */
