.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url("https://i.imgur.com/sio5X2y.png");
  background-size: cover;
  background-position: right;
}

/* .bg-gradient {
  height: 100%;
  width: 100%;
  background-color: #c5b99e;
  background-image: radial-gradient(
      circle farthest-corner at top left,
      rgba(237, 255, 99, 0.838) 0%,
      rgba(225, 243, 97, 0) 30%
    ),
    radial-gradient(
      circle farthest-side at top right,
      rgb(250, 52, 13) 0%,
      rgba(181, 176, 177, 0) 10%
    ),
    radial-gradient(
      circle farthest-corner at bottom right,
      rgb(255, 36, 113) 0%,
      rgba(204, 104, 119, 0) 10%
    ),
    radial-gradient(
      circle farthest-corner at top right,
      rgb(218, 84, 84) 0%,
      rgba(9, 18, 195, 0.5) 50%
    ),
    radial-gradient(
      ellipse at bottom center,
      rgba(254, 43, 0, 1) 0%,
      rgba(254, 43, 0, 0) 50%
    );
} */

.layout .layout-children {
  flex-grow: 1;
}

a:hover {
  color: #00c987;
}

@media only screen and (max-width: 768px) {
  html,
  body {
    width: 100%;
    overflow-x: hidden;
  }
}
