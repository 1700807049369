input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.jam-form {
  margin: 10rem auto;
  width: 80%;
}

#button {
  margin-top: 4rem;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  background-color: #00c987;
  border: none;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  padding: 0.25rem 1.5rem 0.25rem;
}

@media screen and (max-width: 770px) {
  Form {
    margin: 5rem auto;
  }

  .form-control,
  .form-select {
    margin: 0.25rem;
  }

  .jam-form{
    margin: 2rem auto;
  }
}
